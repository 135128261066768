<template>
  <div class='Footer'>
    <div class="warp">
      <div style="margin-left: 20px;">
        <div class="logo" @click="$router.push({name: 'Home'})">
          <img src="@/assets/logo.svg" alt="logo">
        </div>
        <div style="margin-top: 10px;">©2020-{{ year }} 上海伴芯科技有限公司 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank" :style="{color: '#999'}">沪ICP备2020034701号-1</a></div>
      </div>
      <div class="links">
        <div class="item">
          <a class="title" @click="$router.push({name: 'Profile'})">
            <div class="text">{{ $t('profile')}}</div>
          </a>
          <br>
          <a class="i" @click="$router.push({name: 'Profile'})">{{ $t('aboutUs')}}</a>
        </div>
        <div class="item">
          <a class="title" @click="$router.push({name: 'Careers'})">
            <div class="text">{{ $t('careers')}}</div>
          </a>
          <br>
          <a class="i" @click="$router.push({name: 'Careers'})">{{ $t('joinUs')}}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'Footer',
  computed: {
    ...mapState({
      lang:state => state.locale
    }),
    year() {
      const y = new Date()
      return y.getFullYear()
    }
  },
  methods: {
    
  },
  created() {
    
  }
}
</script>
<style lang='less'>
  .Footer {
    width: 100%;
    height: 130px;
    background-color: #f0f0f4;
    line-height: 20px;
    font-size: 12px;
    color: #999;
    min-width: fit-content;
    .warp {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 25px;
      padding-left: 15px;
    }
    .logo {
      width: 165px;
    }
    .links {
      display: flex;
      .item {
        font-weight: 500;
        letter-spacing: 1px;
        padding: 0 15px;
        text-align: left;
        .text {
          line-height: 20px;
          font-size: 14px;
          color: #0050b3;
        }
        .i {
          color: #999;
        }
      }
    }
  }
</style>