<template>
  <div class='Top'>
    <Header />
    <div
      :style="{
        backgroundImage: `url(${info.background.image})`,
        backgroundSize: info.background.size,
        backgroundPosition: info.background.position,
      }"
      class="mainImgBox warp">
      <div 
        class="textBox"
        :style="{
          backgroundColor: info.background.color,
        }"
      > 
        <div
          :style="info.text.style"
          class="warp" 
          style="width: 100%; padding: 0 20px;">
          <div class="title" :style="info.text.titleStyle">{{title}}</div>
          <div class="explain" :style="info.text.explainStyle" v-html="info.text.explain"></div>
          <div><slot /></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Top',
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      
    }
  },
  computed: {
     title: function() {
       return this.info.text.title
     }
  }
}
</script>
<style lang='less' scoped>
  .mainImgBox {
    max-width: 100vw;
    min-height: 500px;
    overflow: hidden;
    position: relative;
    .textBox {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      color: #fff;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      background-repeat: repeat-x;
      .title {
        font-size: 50px;
        line-height: 50px;
        // letter-spacing: 4px;
      }
      .explain {
        margin-top: 50px;
        font-size: 23px;
        // text-align: left;

        // line-height: 35px;
        // width: 655px;
        // display: inline-block;
        // white-space: pre-line;
      }
    }
  }
</style>