export const lang = {
    homeTitle:'Innovation Changes The Future',
    homeTitleStyle:{
        textAlign: "center",
        fontWeight: "bold",
    },
    homeExplain:'Our goal is to enable customers to progress from new product ideas to working products faster than currently possible, We achieve this by bridging the divide between chip concept and chip volume production.',
    homeExplainStyle:{
        textAlign: "justify",

        lineHeight:"35px",
        width: "655px",
        display:"inline-block",
        whiteSpace: "preline",
    },
    profileTitle:'About Us',
    profileContent1:"IC Bench is a leading semiconductor technology company.  Our goal is to enable customers to progress from new product ideas to working products faster than currently possible.  We achieve this by bridging the divide between chip concept and chip volume production.  Our customers need customized chips to advance their products and control their supply chain.IC Bench's expertise is in transforming chip ideas into working chips at a pace unmatched in the industry. We have developed new methodologies to enable this transformation.",
    profileContent2:"IC Bench's team spans the breadth of semiconductor technology to chip design tool creation.  This unique team is our secret to creating new methodologies that advance the semiconductor industry as well as our customer's success.",
    profileContentStyle:{
        letterSpacing:"0.06em",
        textAlign: "justify",
        textJustify:"inter-ideograph",
    },
    profileTopTitle:'IC Bench is innovating the future of the semiconductor industry!',
    profileTopTitleStyle:{
        width: "970px",
        fontWeight: "bold",
        letterSpacing:"0.06em",
        textShadow:"4px 7px 16px #FFFFFF",
    },
    profileTopExplain:"Success is achieved by accelerating customer's achievements!",
    profileTopExplainStyle:{
        width: "970px",
        fontWeight: "bold",
        letterSpacing:"0.06em",
        fontSize:"50px",
        lineHeight:"50px",
        textShadow:"4px 7px 16px #FFFFFF",
    },
    profile:'PROFILE',
    careers:'CAREERS',
    aboutUs:'About us',
    joinUs:'Join us'
}