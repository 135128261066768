<template>
  <div class="Home">
    <Top :info="topInfo" />
    <div class="cards warp">
      <Card :info="cards.profile" />
      <Card :info="cards.careers" />
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapState } from "vuex";

import Card from "@/components/Card";
import homeImg from "@/assets/home.jpg";
import img1 from "@/assets/1.jpg";
import img2 from "@/assets/2.jpg";
let interval;

export default {
  name: "Home",
  data() {
    return {
      topInfo: {
        background: {
          color: "rgba(0, 71, 157, 0.2)",
          image: homeImg,
          size: "100%",
          position: "0 0",
        },
        text: {
          title: this.$t("homeTitle"),
          explain: this.$t("homeExplain"),
          style: {
            textAlign: "center",
            color: "#fff",
          },
          titleStyle: this.$t("homeTitleStyle"),
          explainStyle: this.$t("homeExplainStyle"),
        },
      },
      cards: {
        profile: {
          path: "Profile",
          background: {
            image: img1,
            size: "170%",
            position: "-20px -40px",
          },
          text: this.$t("aboutUs"),
        },
        careers: {
          path: "Careers",
          background: {
            image: img2,
            size: "170%",
            position: "-45px -30px",
          },
          text: this.$t("joinUs"),
        },
      },
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.locale,
    }),
  },
  watch: {
    lang() {
      this.topInfo.text.title = this.$t("homeTitle");
      this.topInfo.text.explain = this.$t("homeExplain");
      this.topInfo.text.titleStyle = this.$t("homeTitleStyle");
      this.topInfo.text.explainStyle = this.$t("homeExplainStyle");
      this.cards.profile.text = this.$t("aboutUs");
      this.cards.careers.text = this.$t("joinUs");
    },
  },
  components: {
    Card,
  },
  mounted() {
    let position = this.topInfo.background.position;
    let left = parseInt(position.split(" ")[0]);
    let top = position.split(" ")[1];
    interval = setInterval(() => {
      left += 1;
      this.topInfo.background.position = `${left}px ${top}`;
    }, 100);
  },
  beforeDestroy() {
    clearInterval(interval);
  },
};
</script>
<style lang="less">
.Home {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  .cards {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}
</style>