export const lang = {
    homeTitle:'创新改变未来',
    homeTitleStyle:{
        textAlign: "center",
    },
    homeExplain:'我们在芯片的设计概念和制造量产之间搭建了一个高效实现的平台，我们的目标是通过平台使客户能够以更快的速度完成从产品创意到生产的全过程。',
    homeExplainStyle:{
        textAlign: "left",
        lineHeight:"35px",
        width: "655px",
        display:"inline-block",
        whiteSpace: "preline",
    },
    profileTitle:'上海伴芯科技有限公司',
    profileContent1:'伴芯是一家行业领先的半导体技术公司。我们在芯片的设计概念和制造量产之间搭建了一个高效实现的平台。我们的目标是通过平台使客户能够以更快的速度完成从产品创意到生产的全过程。当我们的客户需要定制芯片来升级他们的产品和调控他们的供应链，伴芯将凭借创新的硬科技，以业界无可比拟的速度把芯片创意转化为可量产的实际芯片。',
    profileContent2:'伴芯的人才团队覆盖了半导体技术、芯片设计和芯片设计工具开发的每个环节。这样的人才团队是我们创新的秘诀，以新技术作为驱动，推进半导体行业和我们的客户更快迈向成功。',
    profileContentStyle:{
    },
    profileTopTitle:'引领芯片产业变革',
    profileTopTitleStyle:{
        fontWeight: "bold",
    },
    profileTopExplain:'成就客户技术升级',
    profileTopExplainStyle:{
        fontWeight: "bold",
        fontSize:"50px",
        // textShadow:"4px 7px 16px #FFFFFF",
    },
    profile:'公司简介',
    careers:'加入我们',
    aboutUs:'企业介绍',
    joinUs:'招聘信息'
}