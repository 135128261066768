import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({ showSpinner: false })
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '主页',
      keepAlive: true,
      allowBack: false,
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "Profile" */ '../views/Profile.vue'),
    meta: {
      title: '公司简介',
      keepAlive: true,
    }
  },
  {
    path: '/careers',
    name: 'Careers',
    component: () => import(/* webpackChunkName: "Careers" */ '../views/Careers.vue'),
    meta: {
      title: '公司简介',
      keepAlive: true,
    }
  },
  {
    path: '/job',
    name: 'JOB',
    component: () => import(/* webpackChunkName: "ASIC" */ '../views/Job.vue'),
    meta: {
      title: 'JOB',
      keepAlive: true,
    },
    props: true
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  { path: '*', redirect: '/404' }
]

const router = new VueRouter({
  routes,
  // mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
})

router.beforeEach(async (to, from, next) => {
  let allowBack = true    
  if (to.meta.allowBack !== undefined) {
    allowBack = to.meta.allowBack
  }
  if (!allowBack) {
    history.pushState(null, null, location.href)
  }
  store.dispatch('updateAppSetting', {
    allowBack: allowBack
  })
  // start progress bar
  NProgress.start()
  next()
})

router.afterEach((to) => {
  let title = to.meta.title ? to.meta.title + '-' : ''
  document.title = title + 'ICBench'
  NProgress.done()
})

export default router
