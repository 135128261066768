import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../i18n'
import createPersistedState from "vuex-persistedstate"


Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.localStorage,
    reducer(val) {
        return {
        // 只储存state中的assessmentData
        locale: val.locale
      }
   }
  })],
  state: {
    allowBack: true,
    locale: 'en'
  },
  mutations: {
    updateAppSetting(state, newState) {
      state.allowBack = newState.allowBack
    },
    updateLocal(state, newLocal) {
      state.locale = newLocal
      i18n.locale = newLocal
    }
  },
  actions: {
    updateAppSetting({ commit }, newState) {
      commit('updateAppSetting', newState)
    },
    updateLocal({ commit }, payload) {
      commit('updateLocal', payload)
    }
  },
  modules: {
  }
})
