import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import store from './store'
import '@/components/index.js'
import './view-design.js'
Vue.config.productionTip = false

i18n.locale = store.state.locale

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')

