<template>
  <div class="Card" @click="$router.push({ name: info.path })">
    <div class="left">
      <div class="text">
        {{ info.text }}
      </div>
    </div>
    <div
      class="right"
      :style="{
        backgroundImage: `url(${info.background.image})`,
        backgroundSize: info.background.size,
        backgroundPosition: info.background.position,
      }"
    >
      <div class="circle"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Card",
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>
<style lang='less' scoped>
.Card {
  width: 350px;
  height: 160px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 50px;
  display: flex;
  transition: all 0.2s linear;
  cursor: pointer;
  &:hover {
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    transform: translate3d(0, -2px, 0);
    .circle {
      background-color: #fcaf1a !important;
    }
  }
  .left,
  .right {
    width: 100%;
    height: 100%;
  }
  .left {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text {
    color: #00479d;
    font-weight: 500;
    font-size: 18px;
    margin-left: 20px;
  }
  .right {
    width: 190px;
    min-width: 190px;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 5px 5px 0 0;
    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-top: 160px solid #fff;
      border-right: 60px solid transparent;
    }
    .circle {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #00479d;
      background-image: url("../assets/right.svg");
      background-size: 55%;
      background-position: center;
      background-repeat: no-repeat;
      transition: all 0.2s linear;
    }
  }
}
</style>