import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'en', // 语言标识 //this.$i18n.locale // 通过切换locale的值来实现语言切换
    messages: {
      'zh': require('../i18n/language-zh').lang,  //
      'en': require('../i18n/language-en').lang
    }
})

const langTypes = {
  CHINESE : 'zh',
  ENGLISH : 'en'
};

export default i18n;
export {langTypes};