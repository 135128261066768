<template>
  <div class='Header warp'>
    <div class="logo" @click="$router.replace({name: 'Home'})">
      <img src="@/assets/logo.svg" alt="logo">
    </div>
    <div class="links">
      <div class="item">
        <a @click="$router.push({name: 'Profile'})">
          <div class="text">{{ $t('profile')}}</div>
        </a>
      </div>
      <div class="item">
        <a @click="$router.push({name: 'Careers'})">
          <div class="text">{{ $t('careers')}}</div>
        </a>
      </div>
      <div class="item">
        <div @click="setLang('zh')" class="switch" v-bind:class="lang==='zh'?'selected':'unselected'">中文</div>
        <div class="delimiter"> / </div>
        <div @click="setLang('en')" class="switch" v-bind:class="lang==='en'?'selected':'unselected'">EN</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'


export default {
  name: 'Header',
  computed: {
    ...mapState({
      lang:state => state.locale
    })
  },
  methods: {
    setLang (langType) {
      this.$store.dispatch("updateLocal", langType);
    }
  },
  created() {
    
  }
}
</script>
<style lang='less'>
  .Header {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo {
      margin-left: 15px;
      width: 165px;
    }
    .links {
      display: flex;
      .item {
        display: flex;
        font-weight: 500;
        letter-spacing: 1px;
        padding: 0 15px;
        text-align: center;
        a {
          color: #999;
        }
        .switch {
          cursor:pointer;
          line-height: 20px;
          font-size: 16px;
        }
        .selected {
          color: #00479d;
        }
        .unselected {
          color: #999;
        }
        .delimiter {
          line-height: 20px;
          font-size: 16px;
          color: #999;
        }
        .text {
          line-height: 20px;
          font-size: 16px;
        }
      }
    }
  }
</style>